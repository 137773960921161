import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_CLIENTS_REQUEST = 'tdc/FETCH_ALL_CLIENTS_REQUEST';
const FETCH_ALL_CLIENTS_SUCCESS = 'tdc/FETCH_ALL_CLIENTS_SUCCESS';
const FETCH_ALL_CLIENTS_FAILURE = 'tdc/FETCH_ALL_CLIENTS_FAILURE';

const CREATE_CLIENT_REQUEST = 'tdc/CREATE_CLIENT_REQUEST';
const CREATE_CLIENT_SUCCESS = 'tdc/CREATE_CLIENT_SUCCESS';
const CREATE_CLIENT_FAILURE = 'tdc/CREATE_CLIENT_FAILURE';

const DELETE_CLIENT_REQUEST = 'tdc/DELETE_CLIENT_REQUEST';
const DELETE_CLIENT_SUCCESS = 'tdc/DELETE_CLIENT_SUCCESS';
const DELETE_CLIENT_FAILURE = 'tdc/DELETE_CLIENT_FAILURE';

const UPDATE_CLIENT_REQUEST = 'tdc/UPDATE_CLIENT_REQUEST';
const UPDATE_CLIENT_SUCCESS = 'tdc/UPDATE_CLIENT_SUCCESS';
const UPDATE_CLIENT_FAILURE = 'tdc/UPDATE_CLIENT_FAILURE';

export const clientsActionsHandlers = {
  [CREATE_CLIENT_SUCCESS]: (state, action) => {
    return flow(
      set('entities.clients', {
        ...state.entities.clients,
        ...action.response.entities.clients
      }),
      set('loaded.clients', true),
      set('loading.clients', false)
    )(state);
  },
  [FETCH_ALL_CLIENTS_REQUEST]: (state) =>
    flow(set('loaded.clients', false), set('loading.clients', true))(state),
  [FETCH_ALL_CLIENTS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.clients', action.response.entities.clients || {}),
      set('loaded.clients', true),
      set('loading.clients', false)
    )(state);
  },
  [FETCH_ALL_CLIENTS_FAILURE]: (state) =>
    flow(set('loaded.clients', false), set('loading.clients', false))(state),
  [UPDATE_CLIENT_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.clients.${action.client.client_prios_id}`, {
        ...action.client,
        ...action.response
      }),
      set('loaded.clients', true),
      set('loading.clients', false)
    )(state);
  },
  [DELETE_CLIENT_SUCCESS]: (state, action) =>
    flow(set('entities.clients', omit(state.entities.clients, action.client_prios_id)))(state)
};

export function createClient(data) {
  return {
    [CALL_API]: {
      types: [CREATE_CLIENT_REQUEST, CREATE_CLIENT_SUCCESS, CREATE_CLIENT_FAILURE],
      method: 'POST',
      endpoint: '/clients/create-client',
      schema: Schemas.CLIENT,
      body: data
    }
  };
}

export function loadClients(userId, role, limit, search) {
  let endpoint = search
    ? `/clients/${userId}/${role}/${limit}/${search}`
    : `/clients/${userId}/${role}/${limit}`;
  return {
    userId,
    role,
    limit,
    search,
    [CALL_API]: {
      types: [FETCH_ALL_CLIENTS_REQUEST, FETCH_ALL_CLIENTS_SUCCESS, FETCH_ALL_CLIENTS_FAILURE],
      method: 'GET',
      endpoint: endpoint,
      schema: Schemas.CLIENT_ARRAY,
      successMessage: 'Clients chargés avec succès !'
    }
  };
}

export function updateClient(client, data) {
  return {
    client,
    [CALL_API]: {
      types: [UPDATE_CLIENT_REQUEST, UPDATE_CLIENT_SUCCESS, UPDATE_CLIENT_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/clients/${client.client_prios_id}`,
      successMessage: 'Le Client a bien été modifié !'
    }
  };
}

export function deleteClient(client_id) {
  return {
    client_id,
    [CALL_API]: {
      types: [DELETE_CLIENT_REQUEST, DELETE_CLIENT_SUCCESS, DELETE_CLIENT_FAILURE],
      method: 'DELETE',
      endpoint: `/clients/${client_id}`,
      successMessage: 'Le Client a bien été supprimé !'
    }
  };
}
