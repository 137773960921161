import { flow, set } from 'lodash/fp';
import { CALL_API, Schemas } from '../Schemas';

const GET_CONTRATS_CLIENT_REQUEST = 'tdc/GET_CONTRATS_CLIENT_REQUEST';
const GET_CONTRATS_CLIENT_SUCCESS = 'tdc/GET_CONTRATS_CLIENT_SUCCESS';
const GET_CONTRATS_CLIENT_FAILURE = 'tdc/GET_CONTRATS_CLIENT_FAILURE';

export const contratsClientActionsHandlers = {
  [GET_CONTRATS_CLIENT_REQUEST]: (state) =>
    flow(set('loaded.contratsClient', false), set('loading.contratsClient', true))(state),
  [GET_CONTRATS_CLIENT_SUCCESS]: (state, action) => {
    return flow(
      set('entities.contratsClient', action.response.entities.contratsClient || {}),
      set('loaded.contratsClient', true),
      set('loading.contratsClient', false)
    )(state);
  },
  [GET_CONTRATS_CLIENT_FAILURE]: (state) =>
    flow(set('loaded.contratsClient', false), set('loading.contratsClient', false))(state)
};

export function loadContratsClient(userId) {
  return {
    userId,
    [CALL_API]: {
      types: [
        GET_CONTRATS_CLIENT_REQUEST,
        GET_CONTRATS_CLIENT_SUCCESS,
        GET_CONTRATS_CLIENT_FAILURE
      ],
      method: 'GET',
      endpoint: `/contrats/client/${userId}`,
      schema: Schemas.CONTRAT_CLIENT_ARRAY,
      successMessage: 'Contrats client chargées avec succès !'
    }
  };
}
