import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalSuccessResetPasswordSend = () => {
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory();

  const confirm = () => {
    setIsOpen(false);
    history.replace('/login');
  };
  return (
    <Modal {...modalDefaultClass} id="confirm-delete" isOpen={isOpen}>
      <div className="modal-title">
        Mot de passe oublié
        <div
          onClick={() => {
            history.push('/login');
            setIsOpen(false);
          }}
          className="btn-close"
        >
          <i className="far fa-times" />
        </div>
      </div>
      <div className="modal-body">Un email pour définir votre mot de passe vous a été envoyé</div>

      <div className="modal-footer">
        <button className="btn btn-primary" onClick={() => confirm()}>
          <span>Ok</span>
        </button>
      </div>
    </Modal>
  );
};

export default ModalSuccessResetPasswordSend;
