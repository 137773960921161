import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalConfirmDelete from '../modals/ModalConfirmDelete';
import ModalFormUtilisateur from '../modals/ModalFormUtilisateur';

import { DISPLAY_ROLE } from '../../constants/Properties';
import { API_URL } from '../../constants/Config';

const UtilisateurCard = ({ utilisateur, deleteUtilisateur, user }) => {
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalFormUtilisateur, setOpenModalFormUtilisateur] = useState(false);

  return (
    <Fragment>
      <ModalFormUtilisateur
        openModal={openModalFormUtilisateur}
        closeModal={() => setOpenModalFormUtilisateur(false)}
        utilisateur={utilisateur}
        editMode
      />
      <ModalConfirmDelete
        openModal={openModalDelete}
        closeModal={() => setOpenModalDelete(false)}
        actionDelete={() => deleteUtilisateur()}
      />

      <div className="box card">
        <div className="box-content">
          <div className="card-title">
            {utilisateur.prenom} {utilisateur.nom}
            <div className="card-actions">
              {user && user?.role !== 'ADV' && (
                <div className="btn-group">
                  <button className="btn" onClick={() => setOpenModalFormUtilisateur(true)}>
                    <i className="fas fa-pencil" />
                  </button>

                  <button className="btn" onClick={() => setOpenModalDelete(true)}>
                    <i className="fas fa-trash-alt" />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="card-infos">
            {utilisateur && utilisateur.photo && (
              <div className="card-picture">
                <img src={`${API_URL}/uploads/${utilisateur.photo}`} alt="photo" />
              </div>
            )}
            <div className="data-wrapper">
              <div className="data-list">
                <div className="card-data">
                  <i className="fal fa-id-card-alt" />
                  <span>{DISPLAY_ROLE[utilisateur.role]}</span>
                </div>

                <div className="card-data">
                  <i className="fal fa-envelope" />
                  <span>{utilisateur.email}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

UtilisateurCard.propTypes = {
  utilisateur: PropTypes.object.isRequired,
  deleteUtilisateur: PropTypes.func.isRequired
};

export default connect((state) => ({
  user: state.auth && state.auth.utilisateur
}))(UtilisateurCard);
