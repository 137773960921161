import { flow, set } from 'lodash/fp';
import { CALL_API } from '../Schemas';

const GET_GRAPHS_COMMERCIAUX_REQUEST = 'tdc/GET_GRAPHS_COMMERCIAUX_REQUEST';
const GET_GRAPHS_COMMERCIAUX_SUCCESS = 'tdc/GET_GRAPHS_COMMERCIAUX_SUCCESS';
const GET_GRAPHS_COMMERCIAUX_FAILURE = 'tdc/GET_GRAPHS_COMMERCIAUX_FAILURE';

export const uiActionsHandlers = {
  [GET_GRAPHS_COMMERCIAUX_SUCCESS]: (state, action) => {
    return flow(set('ui.graphiques', action.response || {}))(state);
  }
};

export function getGraphsCommerciaux(priosId) {
  return {
    priosId,
    [CALL_API]: {
      types: [
        GET_GRAPHS_COMMERCIAUX_REQUEST,
        GET_GRAPHS_COMMERCIAUX_SUCCESS,
        GET_GRAPHS_COMMERCIAUX_FAILURE
      ],
      method: 'GET',
      endpoint: `/ui/graphs-commerciaux/${priosId}`,
      successMessage: 'Data des graphs chargées avec succès !'
    }
  };
}
