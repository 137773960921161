import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { map, join } from 'lodash';
import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

import ClipLoader from 'react-spinners/ClipLoader';

const ModalConfirmInitCours = ({ cereales, annee, confirm, openModal, closeModal }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const confirmInit = () => {
    setLoading(true);
    return confirm()
      .then(() => {
        setLoading(false);
        closeModal();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };
  return (
    <Modal {...modalDefaultClass} id="confirm-delete" isOpen={openModal}>
      <div className="modal-title">
        Aucun cours disponible
        <div
          onClick={() => {
            closeModal();
            setError(null);
          }}
          className="btn-close"
        >
          <i className="far fa-times" />
        </div>
      </div>
      <div className="modal-body">
        Aucun cours n'est disponible pour l'année {annee}, pour les cereales suivantes : <br />
        <br />
        {join(
          map(cereales, (c) => `${c.libelle}`),
          ', '
        )}
        <br /> <br />
        souhaitez vous les initialiser ?
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="modal-footer">
        <div className="btn-group">
          <button
            onClick={() => {
              closeModal();
              setError(null);
            }}
            className="btn btn-secondary"
          >
            <span>Non</span>
          </button>
          <button className="btn btn-primary" onClick={() => confirmInit()} disabled={loading}>
            {loading ? <ClipLoader size={15} color={'white'} loading={true} /> : <span>Oui</span>}
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalConfirmInitCours.propTypes = {
  confirm: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ModalConfirmInitCours;
