import { CALL_API, Schemas } from '../Schemas';

import { values } from 'lodash';

const LOAD_REQUEST = 'dtcwa/auth/LOAD_REQUEST';
const LOAD_SUCCESS = 'dtcwa/auth/LOAD_SUCCESS';
const LOAD_FAIL = 'dtcwa/auth/LOAD_FAIL';

const LOGIN_REQUEST = 'dtcwa/auth/LOGIN_REQUEST';
const LOGIN_SUCCESS = 'dtcwa/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'dtcwa/auth/LOGIN_FAIL';

const LOGOUT_REQUEST = 'dtcwa/auth/LOGOUT_REQUEST';
const LOGOUT_SUCCESS = 'dtcwa/auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'dtcwa/auth/LOGOUT_FAIL';

const CREATE_PASSWORD_REQUEST = 'dtcwa/auth/CREATE_PASSWORD_REQUEST';
const CREATE_PASSWORD_SUCCESS = 'dtcwa/auth/CREATE_PASSWORD_SUCCESS';
const CREATE_PASSWORD_FAILURE = 'dtcwa/auth/CREATE_PASSWORD_FAILURE';

const RECOVER_PASSWORD_REQUEST = 'dtcwa/auth/RECOVER_PASSWORD_REQUEST';
const RECOVER_PASSWORD_SUCCESS = 'dtcwa/auth/RECOVER_PASSWORD_SUCCESS';
const RECOVER_PASSWORD_FAILURE = 'dtcwa/auth/RECOVER_PASSWORD_FAILURE';

const initialState = {
  loaded: false,
  isConnected: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        utilisateur: action.response
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isConnected: true,
        utilisateur: values(action.response.entities.utilisateur)[0]
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        utilisateur: null,
        loginError: action.error
      };

    case LOGOUT_REQUEST:
      return {
        ...state,
        loggingOut: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        utilisateur: null
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}

export function login(email, password) {
  return {
    [CALL_API]: {
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL],
      method: 'POST',
      endpoint: '/auth/login',
      schema: Schemas.LOGIN,
      body: { email, password },
      applyNormalize: false
    }
  };
}

export function createPassword(data) {
  return {
    [CALL_API]: {
      types: [CREATE_PASSWORD_REQUEST, CREATE_PASSWORD_SUCCESS, CREATE_PASSWORD_FAILURE],
      method: 'PUT',
      endpoint: '/auth/reset-password',
      body: data
    }
  };
}

export function logout() {
  return {
    type: LOGOUT_SUCCESS
  };
}

export function recoverPassword(email) {
  return {
    [CALL_API]: {
      types: [RECOVER_PASSWORD_REQUEST, RECOVER_PASSWORD_SUCCESS, RECOVER_PASSWORD_FAILURE],
      method: 'PUT',
      endpoint: '/auth/password',
      body: {
        email
      }
    }
  };
}
