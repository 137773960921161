import React, { useState } from 'react';

import { connect } from 'react-redux';

import { map, values, filter, groupBy, sumBy } from 'lodash';

import TableListViewChildTables from '../shared/TableListViewChildTables';

import ContratsTableListView from './ContratsTableListView';

const ContratsClientTable = ({ contratsClient, cereales, activeAnneeCollecte }) => {
  const [activeCereale, setActiveCereale] = useState(null);

  const contratsGroupByCereale = groupBy(
    contratsClient &&
      map(
        filter(contratsClient, (c) => c.annee_collecte === activeAnneeCollecte),
        (c) => {
          return { ...c };
        }
      ),
    'cereale_prios_id'
  );

  const childrenTable = [
    activeCereale &&
      filter(contratsClient, (c) => c.cereale_prios_id === activeCereale).length > 0 && {
        component: (
          <ContratsTableListView
            data={contratsGroupByCereale && activeCereale && contratsGroupByCereale[activeCereale]}
          />
        )
      }
  ];

  const openCerealeRow = (cereale) => {
    if (activeCereale && activeCereale === cereale.cereale_prios_id) {
      setActiveCereale(null);
    } else {
      setActiveCereale(cereale.cereale_prios_id);
    }
  };

  const data = [];

  map(contratsGroupByCereale, (contratsClient, cerealeId) => {
    const cereale = cereales[cerealeId] && cereales[cerealeId].libelle;
    const totalTonnage = sumBy(contratsClient, (o) => o.quantite);

    data.push({
      cereale_prios_id: cerealeId,
      cereale,
      totalTonnage
    });
  });

  return (
    <TableListViewChildTables
      className="table-wrapper"
      classTable="table-fixed"
      childColspan="3"
      data={data}
      sortByDefault={'cereale'}
      sortOrderDefault="asc"
      activeRowControlled={activeCereale}
      rowControlled="cereale_prios_id"
      childrenTable={childrenTable}
      cols={() => ({
        accordeon: {
          label: '',
          className: 'btn-arrow',
          render: (value, row) => (
            <span className="accordeon-btn" onClick={() => openCerealeRow(row)}>
              <i
                className={`fal fa-angle-${
                  (activeCereale && row.cereale_prios_id === activeCereale && 'down') || 'right'
                }`}
              />
            </span>
          )
        },
        cereale: {
          label: 'Céréale'
        },
        totalTonnage: {
          label: 'Tonnages totaux',
          className: 'right',
          render: (value) => `${Number(value).toFixed(3)} t`,
          value: (value) => `${Number(value).toFixed(3)} t`
        }
      })}
    />
  );
};

const mapStateToProps = (state) => ({
  contratsClient: state.data.entities.contratsClient && values(state.data.entities.contratsClient),
  cereales: state.data.entities.cereales
});

export default connect(mapStateToProps, {})(ContratsClientTable);
