import React from 'react';

import { connect } from 'react-redux';
import { map } from 'lodash';
import TableListView from '../shared/TableListView';

const CriteresApportsTable = ({ apports, cereales }) => {
  const data = map(apports, (a) => {
    const cereale =
      cereales && cereales[a.cereale_prios_id] && cereales[a.cereale_prios_id].libelle;

    return {
      ...a,
      cereale: cereale
    };
  });

  return (
    <TableListView
      className="table-wrapper"
      data={data}
      sortByDefault="cereale"
      sortOrderDefault="asc"
      cols={() => ({
        cereale_prios_id: {
          label: 'Céréale',
          value: (value) => (cereales && cereales[value] && cereales[value].libelle) || ''
        },
        humidite: {
          label: 'Humidité',
          value: (value) => (value && `${Number(value).toFixed(2)} %`) || 0,
          render: (value) => (value && `${Number(value).toFixed(2)} %`) || 0
        },
        ps: {
          label: 'PS',
          value: (value) => (value && `${Number(value).toFixed(2)}`) || 0,
          render: (value) => (value && `${Number(value).toFixed(2)}`) || 0
        },
        proteine: {
          label: 'Protéine',
          value: (value) => (value && `${Number(value).toFixed(2)} %`) || 0,
          render: (value) => (value && `${Number(value).toFixed(2)} %`) || 0
        },
        impurete: {
          label: 'Impureté',
          value: (value) => (value && `${Number(value).toFixed(2)} %`) || 0,
          render: (value) => (value && `${Number(value).toFixed(2)} %`) || 0
        }
      })}
    />
  );
};

export default connect(
  (state) => ({
    apports: state.data.entities.apports,
    cereales: state.data.entities.cereales
  }),
  {}
)(CriteresApportsTable);
