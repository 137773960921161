import React from 'react';
import { connect } from 'react-redux';

import { Bar } from 'react-chartjs-2';
import { cloneDeep } from 'lodash';

const GraphBars = ({ graphType }) => {
  const dataGraph = cloneDeep(graphType);
  const data = {
    labels: (dataGraph && dataGraph.labels) || [],
    datasets: (dataGraph && dataGraph.datasets) || []
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: false
      }
    }
  };
  return <Bar options={options} data={data} height={300} />;
};

const mapStateToProps = (state, props) => ({
  graphType:
    state.data && state.data.ui && state.data.ui.graphiques && state.data.ui.graphiques[props.type]
});

export default connect(mapStateToProps, {})(GraphBars);
