import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import moment from '../../utils/localMoment';

import { map } from 'lodash';

import TableListView from '../shared/TableListView';
import { eachHourOfInterval } from 'date-fns';

const LastVentesTable = ({ contrats, cereales, clients }) => {
  const data = map(contrats, (c) => {
    const cereale =
      cereales && cereales[c.cereale_prios_id] && cereales[c.cereale_prios_id].libelle;

    const client = clients && clients[c.client_prios_id];

    return {
      ...c,
      date: moment(c.date_engagement).format('DD/MM/YYYY'),
      client: `${(client && client.raison_sociale_1) || ''} ${
        (client && client.raison_sociale_2) || ''
      }`,
      cereale: cereale
    };
  });

  return (
    <TableListView
      className="table-wrapper"
      data={data}
      sortByDefault="date"
      sortOrderDefault="desc"
      cols={() => ({
        date: { label: 'Date' },
        client: {
          label: 'Client',
          value: (row) => row.client,
          render: (value, row) => (
            <NavLink to={`/tableau-de-bord/clients/${row.client_prios_id}`}>{value}</NavLink>
          )
        },
        // client: { label: 'Client', value: (row) => row.client },
        cereale: { label: 'Céréales' },
        quantite: {
          label: 'Tonnage',
          value: (value) => `${value} t`
        },
        prix: { label: 'Cours', value: (value) => `${value}  €/t` }
      })}
    />
  );
};

export default connect(
  (state) => ({
    clients: state.data.entities.clients,
    cereales: state.data.entities.cereales,
    contrats: state.data.entities.contrats
  }),
  {}
)(LastVentesTable);
