export const DISPLAY_ROLE = {
  COMMERCIAL: 'Commercial',
  ADV: 'Adv',
  ADMIN: 'Administrateur'
};

export const TYPES_LIVRAISON = {
  DEPOT: 'Arrivée usine ou dépôt',
  CHAMP: 'Départ champ'
};

export const TYPES_CONTRATS = {
  VENTE: 'Vente',
  FACONNAGE: 'Façonnage'
};

/* export const ROLES_ACCESS = {
  ADMIN: [
    'tableau-de-bord/admin',
    'tableau-de-bord/client/quartier',
    'clients',
    'client',
    'pav',
    'composteur',
    'documents-generiques',
    'faq',
    'administrateurs',
    'usagers',
    'parametres-admin'
  ],
  COMMERCIAL: [
    'tableau-de-bord/exploitant/site',
    'tableau-de-bord/exploitant/quartier',
    'rapport-detailles',
    'pav',
    'composteur',
    'contact-et-commandes',
    'animations-et-communications',
    'documents-reglementaires',
    'parametres',
    'usagers',
    'faq'
  ]
};
 */
