import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from '../../store/modules/auth';
import { purge } from '../../store/modules/settings';
import { DISPLAY_ROLE } from '../../constants/Properties';

const HeaderBar = ({ utilisateur, logout, purge }) => {
  const deconnexion = () => {
    return Promise.resolve()
      .then(() => logout())
      .then(() => purge());
  };

  return (
    <header className="header">
      <div className="container">
        <div className="user-infos"></div>
        <div className="user-block">
          <div className="user-avatar-block">
            <i className="fal fa-user" />
          </div>
          <div className="user-profil">
            <span className="user-name">{utilisateur && utilisateur.prenom}</span>
            <span className="user-type">{utilisateur && DISPLAY_ROLE[utilisateur.role]}</span>
          </div>
          <NavLink className="btn" to="/" onClick={() => deconnexion()}>
            <i className="far fa-sign-out" />
          </NavLink>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  utilisateur: state.auth.utilisateur
});

export default connect(mapStateToProps, { logout, purge })(HeaderBar);
