import { flow, set } from 'lodash/fp';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_CONTRATS_REQUEST = 'tdc/FETCH_ALL_CONTRATS_REQUEST';
const FETCH_ALL_CONTRATS_SUCCESS = 'tdc/FETCH_ALL_CONTRATS_SUCCESS';
const FETCH_ALL_CONTRATS_FAILURE = 'tdc/FETCH_ALL_CONTRATS_FAILURE';

export const contratsActionsHandlers = {
  [FETCH_ALL_CONTRATS_REQUEST]: (state) =>
    flow(set('loaded.contrats', false), set('loading.contrats', true))(state),
  [FETCH_ALL_CONTRATS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.contrats', action.response.entities.contrats || {}),
      set('loaded.contrats', true),
      set('loading.contrats', false)
    )(state);
  },
  [FETCH_ALL_CONTRATS_FAILURE]: (state) =>
    flow(set('loaded.contrats', false), set('loading.contrats', false))(state)
};

export function loadContrats(priosId) {
  return {
    priosId,
    [CALL_API]: {
      types: [FETCH_ALL_CONTRATS_REQUEST, FETCH_ALL_CONTRATS_SUCCESS, FETCH_ALL_CONTRATS_FAILURE],
      method: 'GET',
      endpoint: `/contrats/commercial/${priosId}`,
      schema: Schemas.CONTRAT_ARRAY,
      successMessage: 'Contrats chargées avec succès !'
    }
  };
}
