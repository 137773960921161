import { flow, set } from 'lodash/fp';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_CEREALES_REQUEST = 'tdc/FETCH_ALL_CEREALES_REQUEST';
const FETCH_ALL_CEREALES_SUCCESS = 'tdc/FETCH_ALL_CEREALES_SUCCESS';
const FETCH_ALL_CEREALES_FAILURE = 'tdc/FETCH_ALL_CEREALES_FAILURE';

export const cerealesActionsHandlers = {
  [FETCH_ALL_CEREALES_REQUEST]: (state) =>
    flow(set('loaded.cereales', false), set('loading.cereales', true))(state),
  [FETCH_ALL_CEREALES_SUCCESS]: (state, action) => {
    return flow(
      set('entities.cereales', action.response.entities.cereales || {}),
      set('loaded.cereales', true),
      set('loading.cereales', false)
    )(state);
  },
  [FETCH_ALL_CEREALES_FAILURE]: (state) =>
    flow(set('loaded.cereales', false), set('loading.cereales', false))(state)
};

export function loadCereales() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_CEREALES_REQUEST, FETCH_ALL_CEREALES_SUCCESS, FETCH_ALL_CEREALES_FAILURE],
      method: 'GET',
      endpoint: '/cereales/get-cereales',
      schema: Schemas.CEREALE_ARRAY,
      successMessage: 'Cereales  chargées avec succès !'
    }
  };
}
