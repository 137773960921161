import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { DISPLAY_ROLE } from '../../constants/Properties';

import { connect } from 'react-redux';
import { createUtilisateur, updateUtilisateur } from '../../store/modules/utilisateurs';
import { uploadFiles } from '../../store/modules/uploads';

import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import { find, map, isString, omit } from 'lodash';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalFormUtilisateur = ({
  utilisateur,
  editMode = false,
  openModal,
  closeModal,
  updateUtilisateur,
  createUtilisateur,
  uploadFiles
}) => {
  const [error, setError] = useState(null);

  const required = (value) => (value ? undefined : 'Champ requis !');
  const identity = (value) => value;

  const handleSubmit = (values) => {
    let data = values;

    if (editMode && Number(data.prios_id) === Number(utilisateur.prios_id)) {
      data = omit(data, ['prios_id']);
    }

    if (editMode) {
      if (data.photo && data.photo.length !== 0 && !isString(data.photo)) {
        return uploadFiles({ files: data.photo })
          .then((res) => {
            return updateUtilisateur(utilisateur, { photo: res.response[0] });
          })
          .then(() => closeModal())
          .catch((err) => setError(err));
      } else {
        return updateUtilisateur(utilisateur, data)
          .then(() => closeModal())
          .catch((err) => setError(err));
      }
    }

    if (data.photo && data.photo.length !== 0) {
      return uploadFiles({ files: data.photo })
        .then((res) => {
          createUtilisateur({ ...data, photo: res.response[0] });
        })
        .then(() => closeModal())
        .catch((err) => setError(err));
    } else {
      return createUtilisateur(data)
        .then(() => closeModal())
        .catch((err) => setError(err));
    }
  };

  const optionsRoles = map(DISPLAY_ROLE, (value, key) => {
    return { label: value, value: key };
  });

  const customStyles = { multiValueRemove: (base) => ({ ...base, display: 'none' }) };

  return (
    <Fragment>
      <Modal {...modalDefaultClass} isOpen={openModal}>
        <div className="modal-title">
          {editMode ? 'Modifier un utilisateur' : 'Ajouter un utilisateur'}
          <div
            onClick={() => {
              closeModal();
              setError(null);
            }}
            className="btn-close"
          >
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    validate={required}
                    initialValue={(editMode && utilisateur && utilisateur.prenom) || ''}
                    name="prenom"
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" />
                        <label>Prénom *</label>
                      </div>
                    )}
                  </Field>
                  <Field
                    validate={required}
                    initialValue={(editMode && utilisateur && utilisateur.nom) || ''}
                    name="nom"
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" />
                        <label>Nom *</label>
                      </div>
                    )}
                  </Field>
                  <Field
                    validate={required}
                    initialValue={(editMode && utilisateur && utilisateur.email) || ''}
                    name="email"
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" />
                        <label>E-mail *</label>
                      </div>
                    )}
                  </Field>
                  <Field
                    validate={required}
                    initialValue={(editMode && utilisateur && utilisateur.role) || ''}
                    name="role"
                    component="select"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <Select
                          styles={customStyles}
                          onChange={(data) => {
                            input.onChange(data.value);
                          }}
                          placeholder="Sélectionner un rôle"
                          isMulti={false}
                          autoFocus
                          options={optionsRoles}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          defaultValue={
                            editMode &&
                            utilisateur &&
                            find(optionsRoles, (o) => o.value === utilisateur.role)
                          }
                        />
                        <label>Rôle *</label>
                      </div>
                    )}
                  </Field>
                  {values.role === 'COMMERCIAL' && (
                    <div>
                      <Field
                        validate={required}
                        initialValue={(editMode && utilisateur && utilisateur.prios_id) || null}
                        name="prios_id"
                        component="input"
                        className="field"
                      >
                        {({ input, meta }) => (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}
                            <input {...input} type="number" />
                            <label>Identifiant unique Prios</label>
                          </div>
                        )}
                      </Field>
                      <Field
                        validate={required}
                        initialValue={(editMode && utilisateur && utilisateur.prios_code) || ''}
                        name="prios_code"
                        component="input"
                        className="field"
                      >
                        {({ input, meta }) => (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}
                            <input {...input} type="text" />
                            <label>Code Commercial Prios</label>
                          </div>
                        )}
                      </Field>
                      <Field
                        validate={required}
                        initialValue={(editMode && utilisateur && utilisateur.telephone) || ''}
                        name="telephone"
                        component="input"
                        className="field"
                      >
                        {({ input, meta }) => (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}
                            <input {...input} type="text" />
                            <label>Téléphone *</label>
                          </div>
                        )}
                      </Field>

                      <Field
                        initialValue={(editMode && utilisateur && utilisateur.photo) || ''}
                        name="photo"
                        component="input"
                        parse={identity}
                      >
                        {({ input: { value, onChange, ...input }, meta }) => {
                          return (
                            <div className="field">
                              {meta.error && meta.touched && (
                                <div className="field-error">{meta.error}</div>
                              )}
                              <input
                                {...input}
                                type="file"
                                id="photo"
                                accept="image/png, image/jpeg"
                                onChange={({ target }) => {
                                  return onChange(target.files);
                                }}
                              />
                              <label>Photo</label>
                              <label htmlFor="photo" />
                            </div>
                          );
                        }}
                      </Field>
                    </div>
                  )}
                  {error && <div className="error-message">{error}</div>}
                  <div className="modal-footer">
                    <div className="btn-group">
                      <button
                        onClick={() => {
                          closeModal();
                          setError(null);
                        }}
                        className="btn btn-secondary"
                      >
                        <span>Annuler</span>
                      </button>

                      <button type="submit" className="btn btn-primary">
                        <span>Valider</span>
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

ModalFormUtilisateur.propTypes = {
  utilisateur: PropTypes.object,
  editMode: PropTypes.bool,
  createUtilisateur: PropTypes.func,
  updateUtilisateur: PropTypes.func,
  uploadFiles: PropTypes.func
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  createUtilisateur,
  updateUtilisateur,
  uploadFiles
})(ModalFormUtilisateur);
