import { flow, set } from 'lodash/fp';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_APPORTS_REQUEST = 'tdc/FETCH_ALL_APPORTS_REQUEST';
const FETCH_ALL_APPORTS_SUCCESS = 'tdc/FETCH_ALL_APPORTS_SUCCESS';
const FETCH_ALL_APPORTS_FAILURE = 'tdc/FETCH_ALL_APPORTS_FAILURE';

export const apportsActionsHandlers = {
  [FETCH_ALL_APPORTS_REQUEST]: (state) =>
    flow(set('loaded.apports', false), set('loading.apports', true))(state),
  [FETCH_ALL_APPORTS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.apports', action.response.entities.apports || {}),
      set('loaded.apports', true),
      set('loading.apports', false)
    )(state);
  },
  [FETCH_ALL_APPORTS_FAILURE]: (state) =>
    flow(set('loaded.apports', false), set('loading.apports', false))(state)
};

export function loadApports(priosId) {
  return {
    priosId,
    [CALL_API]: {
      types: [FETCH_ALL_APPORTS_REQUEST, FETCH_ALL_APPORTS_SUCCESS, FETCH_ALL_APPORTS_FAILURE],
      method: 'GET',
      endpoint: `/apports/commercial/${priosId}`,
      schema: Schemas.APPORT_ARRAY,
      successMessage: 'Apports chargées avec succès !'
    }
  };
}
