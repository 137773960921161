import React from 'react';
import TableListView from '../shared/TableListView';

import moment from '../../utils/localMoment';

const ApportsTableListView = ({ data }) => {
  return (
    <TableListView
      className="table-wrapper"
      data={data}
      sortByDefault="created_at"
      sortOrderDefault="desc"
      hideTableHeader
      cols={() => ({
        date_apport: {
          label: 'Date',
          colspan: '2',
          render: (value) => moment(value).format('DD/MM/YYYY')
        },
        humidite: {
          label: 'Humidité',
          value: (value) => `${value}%`,
          render: (value) => `${value}%`
        },
        ps: {
          label: 'PS'
        },
        impurete: {
          label: 'Impureté',
          value: (value) => `${value}%`,
          render: (value) => `${value}%`
        },
        proteine: {
          label: 'Protéine',
          value: (value) => `${value}%`,
          render: (value) => `${value}%`
        },
        quantite: {
          label: 'Quantité',
          className: 'right',
          value: (value) => `${value} t`,
          render: (value) => `${Number(value).toFixed(3)} t`
        }
      })}
    />
  );
};

export default ApportsTableListView;
