export function validateEmail(string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(string);
}

export function validateIdenSymatec(string) {
  const re = /^[a-zA-Z0-9_-]*$/;
  return re.test(string);
}

export function validateIp(string) {
  const re = /^[\\.0-9]/;
  return re.test(string);
}
