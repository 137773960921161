import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadContrats } from '../../store/modules/contrats';
import { loadClients } from '../../store/modules/clients';
import { loadApports } from '../../store/modules/apports';
import { loadCereales } from '../../store/modules/cereales';
import { loadSocietes } from '../../store/modules/societes';
import { getGraphsCommerciaux } from '../../store/modules/ui';

import BaseLayout from '../shared/BaseLayout';
import LastVentesTable from '../../components/tableaux/LastVentesTable';
import CriteresApportsTable from '../../components/tableaux/CriteresApportsTable';

import GraphBars from '../../components/graphs/GraphBars';

const CommercialDashboardPage = ({
  loadClients,
  loadContrats,
  loadApports,
  loadSocietes,
  loadCereales,
  getGraphsCommerciaux,
  user
}) => {
  useEffect(() => {
    loadSocietes();
    loadClients(user.utilisateur_id, user.role);
    loadCereales();
    getGraphsCommerciaux(user.prios_id);
    loadApports(user.prios_id);
    loadContrats(user.prios_id);
  }, []);

  return (
    <BaseLayout>
      <Fragment>
        <div className="container">
          <div className="page-header">
            <div className="page-header-left">
              <div className="page-header-title">Tableau de bord</div>
            </div>
          </div>
          <div className="page-content">
            <div className="grid">
              <div className="col-6_xs-12_sm-12">
                <div className="grid">
                  <div className="col-12">
                    <div className="chart-block">
                      <div className="chart-block-title">Ventes</div>
                      <div className="chart-wrapper">
                        <GraphBars type="graphVentes" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="page-header-title">Les dernières ventes</div>
                    <LastVentesTable />
                  </div>
                </div>
              </div>
              <div className="col-6_xs-12_sm-12">
                <div className="grid">
                  <div className="col-12">
                    <div className="chart-block">
                      <div className="chart-block-title">Apports</div>
                      <div className="chart-wrapper">
                        <GraphBars type="graphApports" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="page-header-title">Critéres de qualité des apports</div>
                    <CriteresApportsTable />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </BaseLayout>
  );
};

CommercialDashboardPage.propTypes = {
  cours: PropTypes.object,
  cereales: PropTypes.array,
  getGraphsCommerciaux: PropTypes.func.isRequired,
  loadContrats: PropTypes.func.isRequired,
  loadClients: PropTypes.func.isRequired,
  loadApports: PropTypes.func.isRequired,
  loadCereales: PropTypes.func.isRequired,
  loadSocietes: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    user: state.auth && state.auth.utilisateur
  }),
  {
    loadSocietes,
    loadCereales,
    loadClients,
    loadContrats,
    loadApports,
    getGraphsCommerciaux
  }
)(CommercialDashboardPage);
