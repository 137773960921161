import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map, filter, toLower, values, orderBy } from 'lodash';
import { deleteUtilisateur } from '../../store/modules/utilisateurs';
import FilterSearch from '../../components/shared/FilterSearch';
import removeAccents from 'remove-accents';
import BaseLayout from '../shared/BaseLayout';
import UtilisateurCard from '../../components/cards/UtilisateurCard';
import ModalFormUtilisateur from '../../components/modals/ModalFormUtilisateur';

const UtilisateursPage = ({ utilisateurs, deleteUtilisateur, user }) => {
  const [openModalFormUtilisateur, setOpenModalFormUtilisateur] = useState(false);
  const [filterUtilisateur, setFilterUtilisateur] = useState('');

  return (
    <BaseLayout>
      <Fragment>
        <ModalFormUtilisateur
          openModal={openModalFormUtilisateur}
          closeModal={() => setOpenModalFormUtilisateur(false)}
        />
        <div className="container">
          <div className="page-header">
            <div className="page-header-left">
              <div className="page-header-title">Utilisateurs</div>
            </div>
            <div className="page-header-toolbar">
              <div className="page-header-toolbar-left">
                <FilterSearch
                  searchFilter={filterUtilisateur}
                  updateSearchFilter={(value) => setFilterUtilisateur(value)}
                />
              </div>
              {user && user?.role !== 'ADV' && (
                <div className="page-header-toolbar-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => setOpenModalFormUtilisateur(true)}
                  >
                    <i className="fas fa-plus" />
                    <span>Ajouter un utilisateur</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="page-content">
            <div className="grid-3_xs-1_sm-1_md-2">
              {map(
                filterUtilisateur
                  ? filter(orderBy(utilisateurs, ['nom'], ['asc']), (o) => {
                      return (
                        removeAccents(toLower(o.nom)).includes(
                          removeAccents(toLower(filterUtilisateur))
                        ) ||
                        removeAccents(toLower(o.email)).includes(
                          removeAccents(toLower(filterUtilisateur))
                        ) ||
                        removeAccents(toLower(o.prenom)).includes(
                          removeAccents(toLower(filterUtilisateur))
                        )
                      );
                    })
                  : orderBy(utilisateurs, ['nom'], ['asc']),
                (utilisateur) => (
                  <div className="col" key={utilisateur.utilisateur_id}>
                    <UtilisateurCard
                      utilisateur={utilisateur}
                      deleteUtilisateur={() => deleteUtilisateur(utilisateur.utilisateur_id)}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </BaseLayout>
  );
};
UtilisateursPage.propTypes = {
  utilisateurs: PropTypes.array.isRequired,
  deleteUtilisateur: PropTypes.func.isRequired
};
export default connect(
  (state) => ({
    user: state.auth && state.auth.utilisateur,
    utilisateurs: state.data.entities.utilisateurs && values(state.data.entities.utilisateurs)
  }),
  {
    deleteUtilisateur
  }
)(UtilisateursPage);
