import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { NavLink } from 'react-router-dom';
import { lowerCase } from 'lodash';

import { createPassword } from '../store/modules/auth';
import { passwordStrength } from 'check-password-strength';
import ModalSuccessCreatePassword from '../components/modals/ModalSuccessCreatePassword';
import { DISPLAY_ROLE } from '../constants/Properties';

import logo from '../../assets/images/logo-terdici_collectes.svg';

const CreatePasswordPage = ({ createPassword }) => {
  const { email, utilisateur_id, token, role } = useParams();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const required = (value) => (value ? undefined : 'Champ requis !');

  const handleSubmit = (values) => {
    const data = values;
    data.authorization_token = token;
    data.reset_token = token;
    data.utilisateur_id = Number(utilisateur_id);

    const isSecurePassword = () => {
      const strength = passwordStrength(data.password);
      return (
        strength.length >= 8 &&
        strength.contains.includes('lowercase') &&
        strength.contains.includes('uppercase') &&
        strength.contains.includes('number') &&
        strength.contains.includes('symbol')
      );
    };

    if (!data.password) {
      setSuccess(false);
      setError('Merci de renseigner votre nouveau mot de passe');
    }

    if (!data.password_repeat) {
      setSuccess(false);
      setError('Merci de renseigner votre confirmation de votre nouveau mot de passe');
    }

    if (data.password === data.password_repeat) {
      if (isSecurePassword()) {
        return createPassword(data)
          .then(() => {
            setError(null);
            setSuccess(true);
          })
          .catch((err) => setError(err));
      } else {
        setSuccess(false);
        setError(
          "Votre mot de passe n'est pas assez sécurisé ! (il doit comporter : au minimum 8 caractères, au moins 1 majuscule, au moins 1 minuscule, au moins 1 chiffre et au moins 1 caractère spécial)"
        );
      }
    } else {
      setSuccess(false);
      setError('Les deux mots de passe ne sont pas identiques');
    }
  };

  return (
    <div className="authentication">
      <div className="authentication-block">
        <div className="box">
          <div className="box-header">
            <img className="logo" src={logo} />
            <div className="box-header-title">
              Définir mon mot de passe {role && lowerCase(DISPLAY_ROLE[role])}
            </div>
          </div>
          <div className="box-content">
            {success && <ModalSuccessCreatePassword />}

            <Form
              onSubmit={handleSubmit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="email"
                    validate={required}
                    component="input"
                    className="field"
                    initialValue={(email && email) || ' '}
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" disabled={true} />
                        <label>Email</label>
                      </div>
                    )}
                  </Field>

                  <Field validate={required} name="password" component="input" className="field">
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input
                          {...input}
                          type="password"
                          maxLength="30"
                          onChange={(value) => {
                            setError(null);
                            input.onChange(value);
                          }}
                        />
                        <label>Mot de passe *</label>
                      </div>
                    )}
                  </Field>
                  <Field
                    validate={required}
                    name="password_repeat"
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input
                          {...input}
                          type="password"
                          maxLength="30"
                          onChange={(value) => {
                            setError(null);
                            input.onChange(value);
                          }}
                        />
                        <label>Confirmer votre mot de passe *</label>
                      </div>
                    )}
                  </Field>

                  {error && <div className="error-message">{error}</div>}
                  <div className="authentication-btn-group">
                    <button type="submit" className="btn btn-primary">
                      <span>Valider</span>
                    </button>
                    <NavLink className="btn-link" to="/login">
                      Revenir à la page de connexion
                    </NavLink>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
      <div className="authentication-overflow"></div>
    </div>
  );
};

CreatePasswordPage.propTypes = {
  createPassword: PropTypes.func.isRequired
};

export default connect(null, { createPassword })(CreatePasswordPage);
