import { isEmpty } from 'lodash';

export function renderTelephonesClient(client) {
  const telephones = [];

  if (client && client.telephone_1) {
    telephones.push(client.telephone_1);
  }
  if (client && client.telephone_2) {
    telephones.push(client.telephone_2);
  }
  if (client && client.telephone_3) {
    telephones.push(client.telephone_3);
  }
  return client && !isEmpty(telephones) ? telephones.join(' / ') : '';
}

export function displayBonCommande(apportId) {
  const data = apportId.split('_');

  if (data && data.length === 2) {
    return data[1];
  } else {
    return null;
  }
}


export function arrayPhonesClient(client) {
  const telephones = [];

  if (client && client.telephone_1) {
    telephones.push(client.telephone_1);
  }
  if (client && client.telephone_2) {
    telephones.push(client.telephone_2);
  }
  if (client && client.telephone_3) {
    telephones.push(client.telephone_3);
  }
  return telephones
}
