import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { arrayPhonesClient } from '../../utils/methodes';

import { loadContratsClient } from '../../store/modules/contratsClient';
import { loadApportsClient } from '../../store/modules/apportsClient';
import { getCommercialPrincipal } from '../../store/modules/commerciaux';

import { NavLink } from 'react-router-dom';
import { map, values } from 'lodash';

import moment from '../../utils/localMoment';

import BaseLayout from '../shared/BaseLayout';

import ContratsClientTable from '../../components/tableaux/ContratsClientTable';
import ApportsClientTable from '../../components/tableaux/ApportsClientTable';
import SwitcherValue from '../../components/shared/SwitcherValue';

const Clientpage = ({
  client,
  societes,
  commercial,
  loadContratsClient,
  loadApportsClient,
  getCommercialPrincipal
}) => {
  const [activeAnneeCollecte, setActiveAnneeCollecte] = useState(moment().year());

  const societeClient =
    client && client.societe_prios_id && societes && societes[client.societe_prios_id];

  useEffect(() => {
    if (client) {
      getCommercialPrincipal(client.client_prios_id);
      loadContratsClient(client.client_prios_id, activeAnneeCollecte);
      loadApportsClient(client.code, activeAnneeCollecte);
    }
  }, []);

  const telephones = (client && arrayPhonesClient(client)) || [];

  return (
    <BaseLayout>
      <div className="container">
        <div className="page-header">
          <div className="page-header-left">
            <div className="btn-group">
              <NavLink className="btn" to="/tableau-de-bord/clients">
                <i className="fal fa-arrow-left" />
                <span>Retour aux clients</span>
              </NavLink>
            </div>
          </div>
          <div className="page-header-right">
            <SwitcherValue
              activeOption={activeAnneeCollecte}
              onChangeValue={(v) => setActiveAnneeCollecte(v)}
              options={
                moment().isSameOrAfter(moment(`${moment().year()}-06-01`).startOf('day'))
                  ? [
                      {
                        label: moment().year(),
                        value: moment().year()
                      },
                      {
                        label: moment().add(1, 'years').year(),
                        value: moment().add(1, 'years').year()
                      }
                    ]
                  : [
                      {
                        label: moment().subtract(1, 'years').year(),
                        value: moment().subtract(1, 'years').year()
                      },
                      {
                        label: moment().year(),
                        value: moment().year()
                      }
                    ]
              }
            />
          </div>
        </div>
        <section>
          <div className="box card">
            <div className="box-content">
              <div className="card-title">
                {client && client.raison_sociale_1} {client && client.raison_sociale_2}
                <div className="card-actions">
                  <div className="card-data column textRight">
                    <span className="card-data-small">Dernière Connexion</span>
                    <span>{client && client.connexion ? moment(client.connexion).format('DD/MM/YYYY - HH:mm') : 'Jamais connecté'}</span>
                  </div>
                </div>
              </div>
              <div className="card-infos">
                <div className="data-wrapper">
                  <div className="data-list">
                    {client && client.siret ? (
                      <div className="card-data">
                        <i className="fal fa-tag"> </i>
                        <span>{client.siret}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {client && client.code ? (
                      <div className="card-data">
                        <i className="fal fa-user-tag" />
                        <span>{client.code}</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="data-list">
                    {client && client.email ? (
                      <a href={`mailto:${client.email}`} target="_blank" className="card-data">
                        <i className="fal fa-envelope" />
                        <span>{client.email}</span>
                      </a>
                    ) : (
                      ''
                    )}
                    {map(telephones, (telephone, i) => (
                      <a href={`tel:${telephone}`} key={i} className="card-data">
                        <i className="fal fa-phone-alt" />
                        <span>{telephone}</span>
                      </a>
                    ))}
                  </div>
                  <div className="data-list">
                    <div className="card-data">
                      <i className="fal fa-building"></i>
                      <span>{(societeClient && societeClient.nom) || ''}</span>
                    </div>
                    <div className="card-data">
                      <i className="fal fa-map-marker-alt" />
                      <span>
                        {(client && client.adresse_2) || ''} {client && client.code_postal}{' '}
                        {client && client.ville}
                      </span>
                    </div>
                    <div className="card-data">
                      <i className="fal fa-briefcase"></i>
                      {(commercial && commercial.prenom) || ''}{' '}
                      {(commercial && commercial.nom) || ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="grid-2_xs-1">
          <div className="col">
            <h2>Contrats</h2>

            <ContratsClientTable activeAnneeCollecte={activeAnneeCollecte} />
          </div>
          <div className="col">
            <h2>Apports</h2>
            <ApportsClientTable activeAnneeCollecte={activeAnneeCollecte} />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

Clientpage.propTypes = {
  client: PropTypes.object,
  utilisateurs: PropTypes.array,
  commerciaux: PropTypes.array,
  loadContratsClient: PropTypes.func.isRequired,
  getCommercialPrincipal: PropTypes.func.isRequired
};

export default connect(
  (state, props) => ({
    commercial: state.data.entities.commerciaux && values(state.data.entities.commerciaux)[0],
    user: state.auth.utilisateur.role,
    societes: state.data.entities.societes,
    client: state.data.entities.clients[props.match.params.clientId],
    utilisateurs: state.data.entities.utilisateurs && values(state.data.entities.utilisateurs),
    clients: state.data.entities.clients && values(state.data.entities.commerciaux)
  }),
  { loadContratsClient, loadApportsClient, getCommercialPrincipal }
)(Clientpage);
