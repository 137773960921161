export default {
  config: {
    isLoading: false
  },
  ui: {
    graphiques: {}
  },
  entities: {
    utilisateurs: {},
    clients: {},
    contratsClient: {},
    apportsClient: {},
    commerciaux: {},
    ventes: {},
    cereales: {},
    cours: {},
    societes: {},
    uploads: {},
    contrats: {},
    apports: {}
  },
  loaded: {
    appstorage: false,
    utilisateurs: false,
    clients: false,
    contratsClient: false,
    apportsClient: false,
    commerciaux: false,
    ventes: false,
    cereales: false,
    cours: false,
    societes: false,
    uploads: false,
    contrats: false,
    apports: false
  },
  loading: {
    utilisateurs: false,
    clients: false,
    contratsClient: false,
    apportsClient: false,
    commerciaux: false,
    ventes: false,
    cereales: false,
    cours: false,
    societes: false,
    uploads: false,
    contrats: false,
    apports: false
  }
};
