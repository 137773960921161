import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { renderTelephonesClient } from '../../utils/methodes';

const ClientCard = ({ client }) => {
  return (
    <NavLink to={`/tableau-de-bord/clients/${client && client.client_prios_id}`}>
      <div className="box card">
        <div className="box-content">
          <div className="card-title">
            {client.raison_sociale_1} {client.raison_sociale_2}
          </div>
          <div className="card-infos">
            <div className="data-wrapper">
              <div className="data-list">
                <div className="card-data">
                  <i className="fal fa-envelope" />
                  <span>{client.email}</span>
                </div>

                <div className="card-data">
                  <i className="fal fa-phone-alt" />
                  <span>{renderTelephonesClient(client)}</span>
                </div>

                <div className="card-data">
                  <i className="fal fa-map-marker-alt" />
                  <span>
                    {client.adresse_2} {client.code_postal} {client.ville}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

ClientCard.propTypes = {
  client: PropTypes.object.isRequired
};
export default ClientCard;
