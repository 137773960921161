import React from 'react';
import TableListView from '../shared/TableListView';

import moment from '../../utils/localMoment';

import { TYPES_CONTRATS } from '../../constants/Properties';

const ContratsTableListView = ({ data }) => {
  return (
    <TableListView
      className="table-wrapper"
      data={data}
      sortByDefault="created_at"
      sortOrderDefault="desc"
      hideTableHeader
      cols={() => ({
        date_engagement: {
          label: 'Date',
          colspan: '2',
          render: (value) => moment(value).format('DD/MM/YYYY')
        },
        type: {
          label: 'Type',
          value: (value) => TYPES_CONTRATS[value]
        },
        prix: {
          label: 'Prix',
          value: (value) => `${value} €/t`
        },
        quantite: {
          label: 'Quantité',
          className: 'right',
          value: (value) => `${Number(value).toFixed(3)} t`
        }
      })}
    />
  );
};

export default ContratsTableListView;
