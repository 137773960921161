import React, { useState, useEffect, Fragment } from 'react';

import { get, map, orderBy, upperCase } from 'lodash';

const TableListViewChildTables = ({
  rowControlled,
  activeRowControlled,
  className,
  classTable,
  childColspan,
  data,
  cols,
  onClickRow,
  hideTableHeader,
  search,
  sortByDefault,
  sortOrderDefault = 'asc',
  childrenTable
}) => {
  const [searchedData, setSearchedData] = useState(data);

  const [sort, setSort] = useState({
    by: sortByDefault,
    order: sortOrderDefault
  });

  useEffect(() => {
    const orderByData = (dataRows) => {
      return orderBy(
        dataRows,
        (entity) => {
          // Order by the result of value() function in cols object if exists

          const value = get(entity, sort.by);
          const colValue = get(cols(), `${sort.by}.value`);
          if (colValue) {
            return upperCase(colValue(value, entity));
          }
          return upperCase(value);
        },
        sort.order
      );
    };

    return setSearchedData(orderByData(data));
  }, [sort, data, search, cols]);

  const setOrder = (by) =>
    setSort({
      by,
      order: sort.by !== by || sort.order !== 'asc' ? 'asc' : 'desc'
    });

  return (
    <div className={className}>
      <div className="table-container">
        <table className={`table${classTable ? ' ' + classTable : ''}`}>
          {!hideTableHeader && (
            <thead className="thead">
              <tr className="tr">
                {map(
                  cols(searchedData),
                  (col, colKey) =>
                    col && (
                      <th
                        key={colKey}
                        className={
                          col.className && col.className + (sort.by === colKey ? ' cln-sorted' : '')
                        }
                        onClick={() => setOrder(colKey)}
                      >
                        <div className="th-content">
                          <div className="entete">
                            {col.label}
                            {col.sublabel ? <div className="sublabel">{col.sublabel}</div> : null}
                            <div className="sort-icon">
                              {sort.by === colKey && (
                                <i
                                  className={`far fa-angle-${sort.order === 'asc' ? 'down' : 'up'}`}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </th>
                    )
                )}
              </tr>
            </thead>
          )}
          <tbody className="tbody">
            {map(
              searchedData,
              (row, rowKey) =>
                row && (
                  <Fragment key={rowKey}>
                    <tr className="tr" key={rowKey}>
                      {map(cols(searchedData), (col, colKey) => {
                        return (
                          col && (
                            <td
                              data-label={col.label}
                              key={colKey}
                              className={`${col.className ? col.className : ''} `}
                              onClick={onClickRow ? () => onClickRow(row) : null}
                            >
                              {(col.render && col.render(row[colKey], row)) ||
                                (col.value && col.value(row[colKey], row)) ||
                                row[colKey]}
                            </td>
                          )
                        );
                      })}
                    </tr>
                    {activeRowControlled && activeRowControlled === row[rowControlled] && (
                      <tr className="data">
                        <td colSpan={childColspan}>{childrenTable[0].component}</td>
                      </tr>
                    )}
                  </Fragment>
                )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableListViewChildTables;
