import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from '../../utils/localMoment';

import {
  findIndex,
  map,
  omit,
  values,
  cloneDeep,
  orderBy,
  filter,
  find,
  isEmpty,
  includes
} from 'lodash';
import { loadUtilisateurs } from '../../store/modules/utilisateurs';
import { loadClients } from '../../store/modules/clients';
import { getCommercialPrincipal } from '../../store/modules/commerciaux';
import { initCoursNewYear, loadCours, updateCours } from '../../store/modules/cours';
import { loadCereales } from '../../store/modules/cereales';

import BaseLayout from '../shared/BaseLayout';
import SwitcherValue from '../../components/shared/SwitcherValue';
import ClipLoader from 'react-spinners/ClipLoader';

import ModalConfirmInitCours from '../../components/modals/ModalConfirmInitCours';
import ModalErrorCotation from '../../components/modals/ModalErrorCotation';

const TarificationPage = ({
  loadCereales,
  loadCours,
  updateCours,
  loadUtilisateurs,
  loadClients,
  getCommercialPrincipal,
  initCoursNewYear,
  cours,
  cereales,
  user
}) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [tempTarifs, setTempTarifs] = useState([]);
  const [submitSave, setSubmitSave] = useState(false);

  const [openModalCotationZero, setModalCotationZero] = useState(false);
  const [openModalNotCours, setOpenModalNotCours] = useState(false);
  const [cerealesNotCours, setCerealesNotCours] = useState(null);

  const optionsAnnees = moment().isSameOrAfter(moment(`${moment().year()}-06-01`).startOf('day'))
    ? [
        {
          label: moment().year(),
          value: moment().year()
        },
        {
          label: moment().add(1, 'years').year(),
          value: moment().add(1, 'years').year()
        }
      ]
    : [
        {
          label: moment().subtract(1, 'years').year(),
          value: moment().subtract(1, 'years').year()
        },
        {
          label: moment().year(),
          value: moment().year()
        }
      ];

  const [activeAnneeCollecte, setActiveAnneeCollecte] = useState(optionsAnnees[0].value);
  const checkCoursExist = (year, coursRes) => {
    const cerealesEmptyCours = [];

    map(cereales, (cereale) => {
      const courThisCereale = find(
        coursRes,
        (c) => c.cereale_prios_id === cereale.cereale_prios_id && c.annee_collecte === Number(year)
      );

      if (!courThisCereale) {
        cerealesEmptyCours.push(cereale);
      }
    });

    setCerealesNotCours(cerealesEmptyCours);

    if (!isEmpty(cerealesEmptyCours)) {
      setOpenModalNotCours(true);
    }
  };

  useEffect(() => {
    if ((user && user.role === 'ADMIN') || (user && user.role === 'ADV')) {
      loadUtilisateurs();
      loadClients(user.utilisateur_id, user.role);
      getCommercialPrincipal();
    }

    return loadCereales()
      .then(() => {
        return loadCours();
      })
      .then((res) => {
        return checkCoursExist(moment().format('YYYY'), res.response.entities.cours);
      });
  }, []);

  useEffect(() => {
    setSubmitSave(false);
    setTempTarifs(
      map(cours, (c) => {
        const tempoTarifs = cloneDeep(tempTarifs);
        const indexTarif = findIndex(tempTarifs, (t) => t.cours_id === c.cours_id);

        return {
          ...c,
          cereale:
            (cereales && cereales[c.cereale_prios_id] && cereales[c.cereale_prios_id].libelle) ||
            '',
          nouveau_cours:
            (!submitSave &&
              tempoTarifs[indexTarif] &&
              tempoTarifs[indexTarif].nouveau_cours &&
              tempoTarifs[indexTarif].nouveau_cours) ||
            ''
        };
      })
    );
  }, [cours]);

  const onChangeActifTarif = (cour) => {
    const tempoTarifs = cloneDeep(tempTarifs);
    const indexTarif = findIndex(tempTarifs, (t) => t.cours_id === cour.cours_id);

    const data = omit(cour, ['_id', 'nouveau_cours', 'date', 'cereale']);
    return updateCours(cour.cours_id, { ...data, suspendu: !cour.suspendu }).then(() => {
      tempoTarifs[indexTarif].suspendu = !cour.suspendu;
      setTempTarifs(tempoTarifs);
    });
  };

  const onChangeTarifs = (courId, data) => {
    const tempoTarifs = cloneDeep(tempTarifs);
    const indexTarif = findIndex(tempTarifs, (t) => t.cours_id === courId);
    tempoTarifs[indexTarif].nouveau_cours = data;
    setTempTarifs(tempoTarifs);
  };

  const saveNewTarifs = () => {
    setLoadingSave(true);

    const pricesToBeUpdated = [];

    const allCours = map(tempTarifs, (t) =>
      isEmpty(t.nouveau_cours) ? null : Number(t.nouveau_cours)
    );

    if (includes(allCours, 0) || allCours.some((cour) => Number(cour) < 0)) {
      setModalCotationZero(true);
    } else {
      map(tempTarifs, (t) => {
        if (t.nouveau_cours) {
          const data = omit(t, ['_id', 'nouveau_cours', 'date', 'cereale']);

          return pricesToBeUpdated.push({
            ...data,
            ancien_cours: t.cours_actuel || null,
            cours_actuel: Number(t.nouveau_cours)
          });
        }
      });
    }

    if (pricesToBeUpdated && pricesToBeUpdated.length > 0) {
      Promise.all(
        map(pricesToBeUpdated, (p) => {
          updateCours(p.cours_id, p)
            .then((res) => {
              const newTarif = res.response;

              const tempoTarifs = cloneDeep(tempTarifs);

              const indexTarif = findIndex(tempTarifs, (t) => t.cours_id === p.cours_id);

              tempoTarifs[indexTarif] = {
                date: moment(newTarif.updated_at).format('DD/MM/YYYY HH:mm'),
                nouveau_cours: '',
                cereale:
                  (cereales &&
                    cereales[p.cereale_prios_id] &&
                    cereales[p.cereale_prios_id].libelle) ||
                  '',
                ...newTarif
              };

              setTempTarifs(tempoTarifs);
            })

            .catch(() => {
              setLoadingSave(false);
            });
        })
      );

      setLoadingSave(false);
    }
    setLoadingSave(false);
    setSubmitSave(true);
  };

  const onChangeYear = (year) => {
    return loadCours()
      .then((res) => {
        return checkCoursExist(Number(year), res.response.entities.cours);
      })
      .then(() => {
        setActiveAnneeCollecte(Number(year));
      });
  };

  return (
    <BaseLayout>
      <Fragment>
        <ModalConfirmInitCours
          openModal={openModalNotCours}
          annee={activeAnneeCollecte}
          cereales={cerealesNotCours}
          confirm={() => initCoursNewYear(activeAnneeCollecte)}
          closeModal={() => {
            setCerealesNotCours(null);
            setOpenModalNotCours(false);
          }}
        />

        <ModalErrorCotation
          openModal={openModalCotationZero}
          closeModal={() => setModalCotationZero(false)}
        />
        <div className="container">
          <div className="page-header">
            <div className="page-header-left">
              <div className="page-header-title">
                Tarification -&nbsp;
                <a
                  href="https://grouepejdis.sharepoint.com/:x:/r/sites/terdici/_layouts/15/Doc.aspx?sourcedoc=%7B8DD1C853-FEAF-41F9-BDFC-74A9D4985150%7D&file=Cotations_collectes_histo.xlsx&action=default&mobileredirect=true"
                  target="_blank"
                >
                  (Historique)
                </a>
              </div>
            </div>
            <div className="page-header-right">
              <SwitcherValue
                activeOption={activeAnneeCollecte}
                onChangeValue={(year) => onChangeYear(year)}
                options={optionsAnnees}
              />
            </div>
          </div>
          <div className="page-content">
            <div className="table-wrapper">
              <div className="table-container">
                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="onlyDesktop">
                        <div className="th-content">
                          <div className="entete">Date</div>
                        </div>
                      </th>
                      <th className="title">
                        <div className="th-content">
                          <div className="entete">Céreale</div>
                        </div>
                      </th>
                      <th className="onlyDesktop">
                        <div className="th-content">
                          <div className="entete">Année</div>
                        </div>
                      </th>
                      <th className="cours-precedent">
                        <div className="th-content">
                          <div className="entete">Cours précédent</div>
                        </div>
                      </th>
                      <th className="cours-actuel">
                        <div className="th-content">
                          <div className="entete">Cours actuel</div>
                        </div>
                      </th>

                      {user && user.role === 'ADMIN' && (
                        <Fragment>
                          <th className="cotation-encours">
                            <div className="th-content">
                              <div className="entete">Nouveau cours</div>
                            </div>
                          </th>
                          <th className="cell-buttons onebtn"></th>
                        </Fragment>
                      )}
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    {map(
                      orderBy(
                        filter(tempTarifs, (t) => t.annee_collecte === activeAnneeCollecte),
                        ['cereale'],
                        ['asc']
                      ),
                      (c) => (
                        <tr className={`tr ${c.suspendu ? 'tr-suspendu' : ''}`} key={c.cours_id}>
                          <td data-label="Date" className="onlyDesktop">
                            {c.suspendu ? '-' : moment(c.updated_at).format('DD/MM/YYYY HH:mm')}
                          </td>
                          <td data-label="Céreale" className="title">
                            {c.cereale}
                          </td>
                          <td data-label="Année" className="onlyDesktop">
                            {c.annee_collecte}
                          </td>
                          <td className="cours cours-precedent" data-label="Cours précédent">
                            {c.ancien_cours && Number(c.ancien_cours).toFixed(2)}
                          </td>

                          <td className="cours cours-actuel" data-label="Cours actuel">
                            {c.cours_actuel && Number(c.cours_actuel).toFixed(2)}
                          </td>

                          {user && user.role === 'ADMIN' && (
                            <Fragment>
                              {c.suspendu ? (
                                <td className="cotation-encours">
                                  <span>Cotation suspendue</span>
                                </td>
                              ) : (
                                <td
                                  className="cours cotation-encours"
                                  data-label="Nouveau cours"
                                  id={`nouveau_tarif_${c.cours_id}`}
                                >
                                  {user && user?.role !== 'ADV' && (
                                    <input
                                      onChange={(e) => onChangeTarifs(c.cours_id, e.target.value)}
                                      type="number"
                                      value={c.nouveau_cours}
                                      lang="fr-FR"
                                    />
                                  )}
                                </td>
                              )}

                              <td className="cell-buttons onebtn">
                                <button className="btn" onClick={() => onChangeActifTarif(c)}>
                                  <i
                                    className={`${
                                      (c.suspendu && 'fa-solid fa-play') || 'fa-regular fa-pause'
                                    }`}
                                  />
                                </button>
                              </td>
                            </Fragment>
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {user && user.role === 'ADMIN' && (
            <div className="page-footer">
              <div className="page-footer-right">
                <button
                  disabled={loadingSave}
                  className="btn btn-primary"
                  onClick={() => saveNewTarifs()}
                >
                  {loadingSave ? (
                    <ClipLoader size={15} color={'white'} loading={true} />
                  ) : (
                    <span>Enregistrer</span>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    </BaseLayout>
  );
};

TarificationPage.propTypes = {
  loadCereales: PropTypes.func.isRequired,
  loadCours: PropTypes.func.isRequired,
  initCoursNewYear: PropTypes.func.isRequired,
  updateCours: PropTypes.func.isRequired,
  loadUtilisateurs: PropTypes.func.isRequired,
  loadClients: PropTypes.func.isRequired,
  getCommercialPrincipal: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    user: state.auth && state.auth.utilisateur,
    cours: state.data.entities.cours && values(state.data.entities.cours),
    cereales: state.data.entities.cereales
  }),
  {
    loadCours,
    loadCereales,
    updateCours,
    initCoursNewYear,
    loadUtilisateurs,
    loadClients,
    getCommercialPrincipal
  }
)(TarificationPage);
