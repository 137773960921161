import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalSuccessCreatePassword = () => {
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory();

  const confirm = () => {
    setIsOpen(false);
    history.replace('/login');
  };
  return (
    <Modal {...modalDefaultClass} id="confirm-delete" isOpen={isOpen}>
      <div className="modal-title">
        Mot de passe défini
        <div
          onClick={() => {
            setIsOpen(false);
            history.replace('/login');
          }}
          className="btn-close"
        >
          <i className="far fa-times" />
        </div>
      </div>
      <div className="modal-body">Votre mot de passe a bien été défini</div>

      <div className="modal-footer">
        <button className="btn btn-primary" onClick={() => confirm()}>
          <span>Ok, je me connecte à mon compte</span>
        </button>
      </div>
    </Modal>
  );
};

export default ModalSuccessCreatePassword;
