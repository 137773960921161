import { flow, set } from 'lodash/fp';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_SOCIETES_REQUEST = 'tdc/FETCH_ALL_SOCIETES_REQUEST';
const FETCH_ALL_SOCIETES_SUCCESS = 'tdc/FETCH_ALL_SOCIETES_SUCCESS';
const FETCH_ALL_SOCIETES_FAILURE = 'tdc/FETCH_ALL_SOCIETES_FAILURE';

export const societesActionsHandlers = {
  [FETCH_ALL_SOCIETES_REQUEST]: (state) =>
    flow(set('loaded.societes', false), set('loading.societes', true))(state),
  [FETCH_ALL_SOCIETES_SUCCESS]: (state, action) => {
    return flow(
      set('entities.societes', action.response.entities.societes || {}),
      set('loaded.societes', true),
      set('loading.societes', false)
    )(state);
  },
  [FETCH_ALL_SOCIETES_FAILURE]: (state) =>
    flow(set('loaded.societes', false), set('loading.societes', false))(state)
};

export function loadSocietes() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_SOCIETES_REQUEST, FETCH_ALL_SOCIETES_SUCCESS, FETCH_ALL_SOCIETES_FAILURE],
      method: 'GET',
      endpoint: '/societes',
      schema: Schemas.SOCIETE_ARRAY,
      successMessage: 'Sociétés chargées avec succès !'
    }
  };
}
