import { flow, set } from 'lodash/fp';
import { CALL_API, Schemas } from '../Schemas';

const GET_APPORTS_CLIENT_REQUEST = 'tdc/GET_APPORTS_CLIENT_REQUEST';
const GET_APPORTS_CLIENT_SUCCESS = 'tdc/GET_APPORTS_CLIENT_SUCCESS';
const GET_APPORTS_CLIENT_FAILURE = 'tdc/GET_APPORTS_CLIENT_FAILURE';

export const apportsClientActionsHandlers = {
  [GET_APPORTS_CLIENT_REQUEST]: (state) =>
    flow(set('loaded.apportsClient', false), set('loading.apportsClient', true))(state),
  [GET_APPORTS_CLIENT_SUCCESS]: (state, action) => {
    return flow(
      set('entities.apportsClient', action.response.entities.apportsClient || {}),
      set('loaded.apportsClient', true),
      set('loading.apportsClient', false)
    )(state);
  },
  [GET_APPORTS_CLIENT_FAILURE]: (state) =>
    flow(set('loaded.apportsClient', false), set('loading.apportsClient', false))(state)
};

export function loadApportsClient(code) {
  return {
    code,
    [CALL_API]: {
      types: [GET_APPORTS_CLIENT_REQUEST, GET_APPORTS_CLIENT_SUCCESS, GET_APPORTS_CLIENT_FAILURE],
      method: 'GET',
      endpoint: `/apports/client/${code}`,
      schema: Schemas.APPORT_CLIENT_ARRAY,
      successMessage: 'Apports client chargées avec succès !'
    }
  };
}
