import { flow, set } from 'lodash/fp';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_COURS_REQUEST = 'tdc/FETCH_ALL_COURS_REQUEST';
const FETCH_ALL_COURS_SUCCESS = 'tdc/FETCH_ALL_COURS_SUCCESS';
const FETCH_ALL_COURS_FAILURE = 'tdc/FETCH_ALL_COURS_FAILURE';

const UPDATE_COUR_REQUEST = 'tdc/UPDATE_COUR_REQUEST';
const UPDATE_COUR_SUCCESS = 'tdc/UPDATE_COUR_SUCCESS';
const UPDATE_COUR_FAILURE = 'tdc/UPDATE_COUR_FAILURE';

const INIT_COUR_YEAR_REQUEST = 'tdc/INIT_COUR_YEAR_REQUEST';
const INIT_COUR_YEAR_SUCCESS = 'tdc/INIT_COUR_YEAR_SUCCESS';
const INIT_COUR_YEAR_FAILURE = 'tdc/INIT_COUR_YEAR_FAILURE';

export const coursActionsHandlers = {
  [FETCH_ALL_COURS_REQUEST]: (state) =>
    flow(set('loaded.cours', false), set('loading.cours', true))(state),
  [FETCH_ALL_COURS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.cours', action.response.entities.cours || {}),
      set('loaded.cours', true),
      set('loading.cours', false)
    )(state);
  },
  [FETCH_ALL_COURS_FAILURE]: (state) =>
    flow(set('loaded.cours', false), set('loading.cours', false))(state),
  [UPDATE_COUR_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.cours.${action.coursId}`, action.response),
      set('loaded.cours', true),
      set('loading.cours', false)
    )(state);
  }
};

export function loadCours() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_COURS_REQUEST, FETCH_ALL_COURS_SUCCESS, FETCH_ALL_COURS_FAILURE],
      method: 'GET',
      endpoint: `/cours`,
      schema: Schemas.COURS_ARRAY,
      successMessage: 'Cours chargées avec succès !'
    }
  };
}
export function updateCours(coursId, data) {
  return {
    coursId,
    [CALL_API]: {
      types: [UPDATE_COUR_REQUEST, UPDATE_COUR_SUCCESS, UPDATE_COUR_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/cours/${coursId}`,
      successMessage: 'Le cour a bien été modifié !'
    }
  };
}
export function initCoursNewYear(year) {
  return {
    year,
    [CALL_API]: {
      types: [INIT_COUR_YEAR_REQUEST, INIT_COUR_YEAR_SUCCESS, INIT_COUR_YEAR_FAILURE],
      method: 'POST',
      endpoint: `/cours/init-cour/${year}`,
      successMessage: 'Init cours réussi',
      successNext: [() => loadCours()]
    }
  };
}
