import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import logo from '../../../assets/images/logo-terdici_collectes-blanc.svg';

const MainNav = ({ utilisateur }) => {
  return (
    <nav className="main_nav">
      <div className="main_nav-header">
        <img className="logo" src={logo} />
      </div>
      <div className="main_nav-content">
        <ul className="main_nav-menu">
          {(utilisateur.role === 'ADMIN' || utilisateur.role === 'ADV') && (
            <li>
              <NavLink to="/tableau-de-bord/tarification">
                <i className="fa-solid fa-calculator-simple"></i>
                <span>Tarification</span>
              </NavLink>
            </li>
          )}
          {utilisateur.role === 'COMMERCIAL' && (
            <Fragment>
              <li>
                <NavLink to="/tableau-de-bord/commercial">
                  <i className="fa-solid fa-chart-mixed"></i>
                  <span>Tableau de bord</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/tableau-de-bord/tarification">
                  <i className="fa-solid fa-calculator-simple"></i>
                  <span>Tarification</span>
                </NavLink>
              </li>
            </Fragment>
          )}
          <li>
            <NavLink to="/tableau-de-bord/clients">
              <i className="fa-solid fa-briefcase"></i>
              <span>Clients</span>
            </NavLink>
          </li>
          {(utilisateur.role === 'ADMIN' || utilisateur.role === 'ADV') && (
            <li>
              <NavLink to="/tableau-de-bord/utilisateurs">
                <i className="fa-solid fa-user-group"></i>
                <span>Utilisateurs</span>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

MainNav.propTypes = {
  utilisateur: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ utilisateur: state.auth.utilisateur });

export default connect(mapStateToProps, {})(MainNav);
