import React, { useEffect } from 'react';
import { withRouter, BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { requireAuthentication } from '../components/hoc/AuthenticatedComponent';

import UtilisateursPage from './utilisateurs/UtilisateursPage';
import ClientsPage from './clients/ClientsPage';
import ClientPage from './clients/ClientPage';
import TarificationPage from './tarification/TarificationPage';
import CommercialDashboardPage from './commercial/CommercialDashboardPage';
import LoginPage from './auth/LoginPage';
import CreatePasswordPage from './CreatePasswordPage';
import PasswordOubliePage from './auth/PasswordOubliePage';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

const Router = function () {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          {/* Route avec authentification test */}
          <Route
            exact
            path="/tableau-de-bord/tarification"
            component={requireAuthentication(TarificationPage)}
          />
          <Route
            exact
            path="/tableau-de-bord/utilisateurs"
            component={requireAuthentication(UtilisateursPage)}
          />
          <Route
            exact
            path="/tableau-de-bord/clients"
            component={requireAuthentication(ClientsPage)}
          />
          <Route
            exact
            path="/tableau-de-bord/clients/:clientId"
            component={requireAuthentication(ClientPage)}
          />
          <Route
            exact
            path="/tableau-de-bord/commercial"
            component={requireAuthentication(CommercialDashboardPage)}
          />
          {/* Fin des Routes avec authentification */}
          <Route
            exact
            path="/definir-mot-de-passe/:role?/:email?/:utilisateur_id?/:token?"
            component={CreatePasswordPage}
          />
          <Route exact path="/mot-de-passe-oublie" component={PasswordOubliePage} />
          {/* Par default, on envoie la page sur login */}
          <Route exact path="*" component={LoginPage} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Router;
