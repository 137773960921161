import React, { useState } from 'react';

import { connect } from 'react-redux';

import { map, values, filter, groupBy, sumBy } from 'lodash';

import TableListViewChildTables from '../shared/TableListViewChildTables';

import ApportsTableListView from './ApportsTableListView';

const ApportsClientTable = ({ apportsClient, cereales, activeAnneeCollecte }) => {
  const [activeCereale, setActiveCereale] = useState(null);

  const apportsGroupByCereale = groupBy(
    apportsClient &&
      map(
        filter(apportsClient, (c) => c.annee_collecte === activeAnneeCollecte),
        (a) => {
          return { ...a };
        }
      ),
    'cereale_prios_id'
  );

  const childrenTable = [
    activeCereale &&
      filter(apportsClient, (c) => c.cereale_prios_id === activeCereale).length > 0 && {
        component: (
          <ApportsTableListView
            data={apportsGroupByCereale && activeCereale && apportsGroupByCereale[activeCereale]}
          />
        )
      }
  ];

  const openCerealeRow = (cereale) => {
    if (activeCereale && activeCereale === cereale.cereale_prios_id) {
      setActiveCereale(null);
    } else {
      setActiveCereale(cereale.cereale_prios_id);
    }
  };

  const data = [];

  map(apportsGroupByCereale, (apportsCereale, cerealeId) => {
    const humidite =
      sumBy(apportsCereale, (o) => o.humidite * o.quantite) /
      sumBy(apportsCereale, (o) => o.quantite);

    const ps =
      sumBy(apportsCereale, (o) => o.ps * o.quantite) / sumBy(apportsCereale, (o) => o.quantite);

    const impurete =
      sumBy(apportsCereale, (o) => o.impurete * o.quantite) /
      sumBy(apportsCereale, (o) => o.quantite);

    const proteine =
      sumBy(apportsCereale, (o) => o.proteine * o.quantite) /
      sumBy(apportsCereale, (o) => o.quantite);
    const totalTonnage = sumBy(apportsCereale, (o) => o.quantite);

    const cereale = cereales[cerealeId] && cereales[cerealeId].libelle;

    data.push({
      cereale_prios_id: cerealeId,
      cereale,
      humidite,
      ps,
      proteine,
      impurete,
      totalTonnage
    });
  });

  return (
    <TableListViewChildTables
      className="table-wrapper"
      classTable="table-fixed"
      childColspan="7"
      data={data}
      sortByDefault={'cereale'}
      sortOrderDefault="asc"
      activeRowControlled={activeCereale}
      rowControlled="cereale_prios_id"
      childrenTable={childrenTable}
      cols={() => ({
        accordeon: {
          label: '',
          className: 'btn-arrow',
          render: (value, row) => (
            <span className="accordeon-btn" onClick={() => openCerealeRow(row)}>
              <i
                className={`fal fa-angle-${
                  (activeCereale && row.cereale_prios_id === activeCereale && 'down') || 'right'
                }`}
              />
            </span>
          )
        },
        cereale: {
          label: 'Céréale'
        },
        humidite: {
          label: 'Humidité',
          value: (value) => `${(value && Number(value).toFixed(2)) || 0}%`,
          render: (value) => `${(value && Number(value).toFixed(2)) || 0}%`
        },
        ps: {
          label: 'PS',
          value: (value) => `${(value && Number(value).toFixed(2)) || 0}`,
          render: (value) => `${(value && Number(value).toFixed(2)) || 0}`
        },
        impurete: {
          label: 'Impureté',
          value: (value) => `${(value && Number(value).toFixed(2)) || 0}%`,
          render: (value) => `${(value && Number(value).toFixed(2)) || 0}%`
        },
        proteine: {
          label: 'Protéine',
          value: (value) => `${(value && Number(value).toFixed(2)) || 0}%`,
          render: (value) => `${(value && Number(value).toFixed(2)) || 0}%`
        },
        totalTonnage: {
          label: 'Tonnages total',
          className: 'right',
          value: (value) => `${Number(value).toFixed(3)} t`,
          render: (value) => `${Number(value).toFixed(3)} t`
        }
      })}
    />
  );
};

const mapStateToProps = (state) => ({
  apportsClient: state.data.entities.apportsClient && values(state.data.entities.apportsClient),
  cereales: state.data.entities.cereales
});

export default connect(mapStateToProps, {})(ApportsClientTable);
