import { CALL_API } from '../Schemas';

const UPLOAD_PHOTO_REQUEST = 'dtcwa/uploads/UPLOAD_PHOTO_REQUEST';
const UPLOAD_PHOTO_SUCCESS = 'dtcwa/uploads/UPLOAD_PHOTO_SUCCESS';
const UPLOAD_PHOTO_FAILURE = 'dtcwa/uploads/UPLOAD_PHOTO_FAILURE';

export const uploadsActionsHandlers = {};

export function uploadFiles(files) {
  return {
    [CALL_API]: {
      types: [UPLOAD_PHOTO_REQUEST, UPLOAD_PHOTO_SUCCESS, UPLOAD_PHOTO_FAILURE],
      method: 'POST',
      endpoint: '/uploads',
      body: files,
      upload: true
    }
  };
}
