import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_UTILISATEURS_REQUEST = 'tdc/FETCH_ALL_UTILISATEURS_REQUEST';
const FETCH_ALL_UTILISATEURS_SUCCESS = 'tdc/FETCH_ALL_UTILISATEURS_SUCCESS';
const FETCH_ALL_UTILISATEURS_FAILURE = 'tdc/FETCH_ALL_UTILISATEURS_FAILURE';

const CREATE_UTILISATEUR_REQUEST = 'tdc/CREATE_UTILISATEUR_REQUEST';
const CREATE_UTILISATEUR_SUCCESS = 'tdc/CREATE_UTILISATEUR_SUCCESS';
const CREATE_UTILISATEUR_FAILURE = 'tdc/CREATE_UTILISATEUR_FAILURE';

const DELETE_UTILISATEUR_REQUEST = 'tdc/DELETE_UTILISATEUR_REQUEST';
const DELETE_UTILISATEUR_SUCCESS = 'tdc/DELETE_UTILISATEUR_SUCCESS';
const DELETE_UTILISATEUR_FAILURE = 'tdc/DELETE_UTILISATEUR_FAILURE';

const UPDATE_UTILISATEUR_REQUEST = 'tdc/UPDATE_UTILISATEUR_REQUEST';
const UPDATE_UTILISATEUR_SUCCESS = 'tdc/UPDATE_UTILISATEUR_SUCCESS';
const UPDATE_UTILISATEUR_FAILURE = 'tdc/UPDATE_UTILISATEUR_FAILURE';

export const utilisateursActionsHandlers = {
  [CREATE_UTILISATEUR_SUCCESS]: (state, action) => {
    return flow(
      set('entities.utilisateurs', {
        ...state.entities.utilisateurs,
        ...action.response.entities.utilisateurs
      }),
      set('loaded.utilisateurs', true),
      set('loading.utilisateurs', false)
    )(state);
  },
  [FETCH_ALL_UTILISATEURS_REQUEST]: (state) =>
    flow(set('loaded.utilisateurs', false), set('loading.utilisateurs', true))(state),
  [FETCH_ALL_UTILISATEURS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.utilisateurs', action.response.entities.utilisateurs || {}),
      set('loaded.utilisateurs', true),
      set('loading.utilisateurs', false)
    )(state);
  },
  [FETCH_ALL_UTILISATEURS_FAILURE]: (state) =>
    flow(set('loaded.utilisateurs', false), set('loading.utilisateurs', false))(state),
  [UPDATE_UTILISATEUR_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.utilisateurs.${action.utilisateur.utilisateur_id}`, {
        ...action.utilisateur,
        ...action.response
      }),
      set('loaded.utilisateurs', true),
      set('loading.utilisateurs', false)
    )(state);
  },
  [DELETE_UTILISATEUR_SUCCESS]: (state, action) =>
    flow(set('entities.utilisateurs', omit(state.entities.utilisateurs, action.utilisateur_id)))(
      state
    )
};

export function createUtilisateur(data, photoId) {
  return {
    [CALL_API]: {
      types: [CREATE_UTILISATEUR_REQUEST, CREATE_UTILISATEUR_SUCCESS, CREATE_UTILISATEUR_FAILURE],
      method: 'POST',
      endpoint: '/utilisateurs/create-utilisateur',
      schema: Schemas.UTILISATEUR,
      body: data
    }
  };
}

export function loadUtilisateurs() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_UTILISATEURS_REQUEST,
        FETCH_ALL_UTILISATEURS_SUCCESS,
        FETCH_ALL_UTILISATEURS_FAILURE
      ],
      method: 'GET',
      endpoint: '/utilisateurs/get-utilisateurs',
      schema: Schemas.UTILISATEUR_ARRAY,
      successMessage: 'Utilisateurs chargés avec succès !'
    }
  };
}

export function updateUtilisateur(utilisateur, data) {
  return {
    utilisateur,
    [CALL_API]: {
      types: [UPDATE_UTILISATEUR_REQUEST, UPDATE_UTILISATEUR_SUCCESS, UPDATE_UTILISATEUR_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/utilisateurs/${utilisateur.utilisateur_id}`,
      successMessage: "L'Utilisateur a bien été modifié !"
    }
  };
}

export function deleteUtilisateur(utilisateur_id) {
  return {
    utilisateur_id,
    [CALL_API]: {
      types: [DELETE_UTILISATEUR_REQUEST, DELETE_UTILISATEUR_SUCCESS, DELETE_UTILISATEUR_FAILURE],
      method: 'DELETE',
      endpoint: `/utilisateurs/${utilisateur_id}`,
      successMessage: "L'Utilisateur' a bien été supprimé !"
    }
  };
}
