import { schema } from 'normalizr';

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/gaearon/normalizr
const loginSchema = new schema.Entity('utilisateur', {}, { idAttribute: 'utilisateur_id' });
const utilisateurSchema = new schema.Entity('utilisateurs', {}, { idAttribute: 'utilisateur_id' });
const clientSchema = new schema.Entity('clients', {}, { idAttribute: 'client_prios_id' });
const commercialchema = new schema.Entity('commerciaux', {}, { idAttribute: 'utilisateur_id' });
const venteSchema = new schema.Entity('ventes', {}, { idAttribute: 'vente_id' });
const cerealeSchema = new schema.Entity('cereales', {}, { idAttribute: 'cereale_prios_id' });
const coursSchema = new schema.Entity('cours', {}, { idAttribute: 'cours_id' });
const contratSchema = new schema.Entity('contrats', {}, { idAttribute: 'contrat_id' });
const contratClientSchema = new schema.Entity('contratsClient', {}, { idAttribute: 'contrat_id' });
const apportSchema = new schema.Entity('apports', {}, { idAttribute: 'cereale_prios_id' });
const apportClientSchema = new schema.Entity('apportsClient', {}, { idAttribute: 'apport_id' });
const societeSchema = new schema.Entity('societes', {}, { idAttribute: 'societe_prios_id' });
// Schemas for API responses.
export const Schemas = {
  LOGIN: loginSchema,
  UTILISATEUR: utilisateurSchema,
  UTILISATEUR_ARRAY: new schema.Array(utilisateurSchema),
  CLIENT: clientSchema,
  CLIENT_ARRAY: new schema.Array(clientSchema),
  COMMERCIAL: commercialchema,
  COMMERCIAL_ARRAY: new schema.Array(commercialchema),
  VENTE: venteSchema,
  VENTE_ARRAY: new schema.Array(venteSchema),
  CEREALE: cerealeSchema,
  CEREALE_ARRAY: new schema.Array(cerealeSchema),
  COURS: coursSchema,
  COURS_ARRAY: new schema.Array(coursSchema),
  CONTRAT: contratSchema,
  CONTRAT_ARRAY: new schema.Array(contratSchema),
  CONTRAT_CLIENT: contratClientSchema,
  CONTRAT_CLIENT_ARRAY: new schema.Array(contratClientSchema),
  APPORT: apportSchema,
  APPORT_ARRAY: new schema.Array(apportSchema),
  APPORT_CLIENT: apportClientSchema,
  APPORT_CLIENT_ARRAY: new schema.Array(apportClientSchema),
  SOCIETE: societeSchema,
  SOCIETE_ARRAY: new schema.Array(societeSchema)
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');
