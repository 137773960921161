import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadClients } from '../../store/modules/clients';
import { loadSocietes } from '../../store/modules/societes';

import { map, filter, toLower, values, orderBy } from 'lodash';
import PulseLoader from 'react-spinners/PulseLoader';

import FilterSearch from '../../components/shared/FilterSearch';
import removeAccents from 'remove-accents';
import BaseLayout from '../shared/BaseLayout';
import ClientCard from '../../components/cards/ClientCard';

const ClientsPage = ({ clients, user, loadClients, loadSocietes }) => {
  const [filterClient, setFilterClient] = useState('');

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(clients);

  useEffect(() => {
    loadSocietes();
  }, []);

  useEffect(() => {
    loadClients(user.utilisateur_id, user.role, 35).then((res) => {
      const resData = values(res.response.entities.clients);
      setData(resData);
      setLoading(false);
    });
  }, []);

  const onChangeSearch = (value) => {
    setFilterClient(value);
    loadClients(user.utilisateur_id, user.role, 35, value).then((res) => {
      const resData = values(res.response.entities.clients);

      setLoading(false);

      setData(resData);
    });
  };

  return (
    <BaseLayout>
      <div className="container">
        <div className="page-header">
          <div className="page-header-left">
            <div className="page-header-title">Clients</div>
          </div>
          <div className="page-header-toolbar">
            <div className="page-header-toolbar-left">
              <FilterSearch
                searchFilter={filterClient}
                updateSearchFilter={(value) => onChangeSearch(value)}
              />
            </div>
            <div className="page-header-toolbar-right"></div>
          </div>
        </div>

        <div className="page-content">
          {loading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <p>Chargement en cours ...</p>
                <PulseLoader size={15} color={'#297764'} loading={true} />
              </div>
            </div>
          ) : (
            <div className="grid-3_xs-1_sm-1_md-2">
              {map(orderBy(data, ['raison_sociale_2'], ['asc']), (client) => (
                <div className="col" key={client.client_prios_id}>
                  <ClientCard client={client} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </BaseLayout>
  );
};
ClientsPage.propTypes = {
  clients: PropTypes.array,
  loadClients: PropTypes.func.isRequired,
  loadSocietes: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    user: state.auth && state.auth.utilisateur,
    clients: state.data.entities.clients && values(state.data.entities.clients)
  }),
  { loadClients, loadSocietes }
)(ClientsPage);
