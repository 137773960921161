import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

export function requireAuthentication(Component) {
  const AuthenticatedComponent = (props) => {
    useEffect(() => {
      checkAuth();
    }, []);

    useEffect(() => {
      checkAuth();
    }, [props.location]);

    const checkAuth = () => {
      // Checke le rôle s'il a accès à la page. Sinon redirection (on saute cette étape si on veut accéder à la page 'tableau de bord')
      if (!props.utilisateur) {
        // const redirectAfterLogin = props.location.pathname;
        props.history.replace(`/login`);
      }
    };
    return <Fragment>{props.utilisateur ? <Component {...props} /> : null}</Fragment>;
  };

  const mapStateToProps = (state) => ({
    utilisateur: state.auth.utilisateur
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
}
