import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { NavLink, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import { validateEmail } from '../../utils/StringValidator';
import { login } from '../../store/modules/auth';
import { setConfig } from '../../store/modules/globals';

import { version } from '../../../package.json';
import logo from '../../../assets/images/logo-terdici_collectes.svg';

const LoginPage = ({ login, setConfig }) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const required = (value) => (value ? undefined : 'Champ requis !');

  const requiredEmail = (value) =>
    value ? (validateEmail(value) ? undefined : 'Email incorrect') : 'Champ requis !';

  const handleSubmit = (values) => {
    return login(values.email, values.password)
      .then((res) => {
        const utilisateur = Object.values(res.response.entities.utilisateur)[0];
        if (utilisateur.role === 'ADMIN' || 'ADV') {
          return history.replace(`tableau-de-bord/tarification`);
        }
        if (utilisateur.role === 'COMMERCIAL') {
          return history.replace(`tableau-de-bord/commercial`);
        }
      })
      .then(() => setConfig('isLoading', false))
      .catch((err) => setError(err));
  };

  return (
    <Fragment>
      <div className="authentication">
        <div className="authentication-block">
          <div className="box">
            <div className="box-header">
              <img className="logo" src={logo} />
            </div>
            <div className="box-content">
              <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      name="email"
                      validate={requiredEmail}
                      component="input"
                      className="field"
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" />
                          <label>Email</label>
                        </div>
                      )}
                    </Field>

                    <Field validate={required} name="password" component="input" className="field">
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input
                            {...input}
                            type="password"
                            onChange={(value) => {
                              setError(null);
                              input.onChange(value);
                            }}
                          />
                          <label>Mot de passe</label>
                        </div>
                      )}
                    </Field>

                    {error && <div className="error-message">{error}</div>}

                    <div className="authentication-btn-group">
                      <button type="submit" className="btn btn-primary">
                        <span>Je me connecte</span>
                      </button>
                      <NavLink className="btn-link text-center" to="/mot-de-passe-oublie">
                        Mot de passe oublié
                      </NavLink>
                      <div className="version">v.{version}</div>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
        <div className="authentication-overflow"></div>
      </div>
    </Fragment>
  );
};

export default connect(null, { login, setConfig })(LoginPage);
