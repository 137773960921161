import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalErrorCotation = ({ openModal, closeModal }) => {
  return (
    <Modal {...modalDefaultClass} id="confirm-delete" isOpen={openModal}>
      <div className="modal-title">
        Erreur de saisie
        <div
          onClick={() => {
            closeModal();
          }}
          className="btn-close"
        >
          <i className="far fa-times" />
        </div>
      </div>
      <div className="modal-body">Veuillez renseigner un cours supérieur à 0</div>

      <div className="modal-footer">
        <div className="btn-group">
          <button
            onClick={() => {
              closeModal();
            }}
            className="btn btn-primary"
          >
            <span>OK</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalErrorCotation.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ModalErrorCotation;
