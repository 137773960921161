import initialState from '../initialState';
import '../../index';

import { uploadsActionsHandlers } from './uploads';
import { utilisateursActionsHandlers } from './utilisateurs';
import { clientsActionsHandlers } from './clients';
import { commerciauxActionsHandlers } from './commerciaux';
import { societesActionsHandlers } from './societes';
import { cerealesActionsHandlers } from './cereales';
import { coursActionsHandlers } from './cours';
import { contratsActionsHandlers } from './contrats';
import { apportsActionsHandlers } from './apports';
import { contratsClientActionsHandlers } from './contratsClient';
import { apportsClientActionsHandlers } from './apportsClient';

import { uiActionsHandlers } from './ui';

const actionsHandlers = {
  ...uploadsActionsHandlers,
  ...utilisateursActionsHandlers,
  ...clientsActionsHandlers,
  ...coursActionsHandlers,
  ...commerciauxActionsHandlers,
  ...societesActionsHandlers,
  ...cerealesActionsHandlers,
  ...contratsActionsHandlers,
  ...apportsActionsHandlers,
  ...contratsClientActionsHandlers,
  ...apportsClientActionsHandlers,
  ...uiActionsHandlers
};

export default function reducer(state = initialState, action) {
  const reduceFn = actionsHandlers[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
