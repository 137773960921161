import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { recoverPassword } from '../../store/modules/auth';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import ModalSuccessResetPasswordSend from '../../components/modals/ModalSuccessResetPasswordSend';

import logo from '../../../assets/images/logo-terdici_collectes.svg';

const PasswordOubliePage = ({ recoverPassword }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const required = (value) => (value ? undefined : 'Champ requis !');

  const handleSubmit = (values) => {
    return recoverPassword(values.email)
      .then(() => {
        setError(null);
        setSuccess(true);
      })
      .catch((err) => {
        setSuccess(false);
        setError(err);
      });
  };

  return (
    <Fragment>
      {success && <ModalSuccessResetPasswordSend />}
      <div className="authentication">
        <div className="authentication-block">
          <div className="box">
            <div className="box-header">
              <img className="logo" src={logo} />
              <div className="box-header-title">Réinitialisez votre mot de passe</div>
            </div>
            <div className="box-content">
              <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name="email" validate={required} component="input" className="field">
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" />
                          <label>Email</label>
                        </div>
                      )}
                    </Field>
                    <div className="authentication-btn-group">
                      {error && (
                        <div className="error-block ">
                          <span className="error-icon">
                            <i className="fas fa-exclamation" />
                          </span>
                          <div className="error-message">{error}</div>
                        </div>
                      )}
                      <button type="submit" className="btn btn-primary">
                        Valider
                      </button>

                      <NavLink className="btn-link" to="/login">
                        Revenir à la page de connexion
                      </NavLink>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
        <div className="authentication-overflow"></div>
      </div>
    </Fragment>
  );
};

PasswordOubliePage.propTypes = {
  recoverPassword: PropTypes.func.isRequired
};

export default connect(null, { recoverPassword })(PasswordOubliePage);
